
import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CmsService} from '@app/_services/cms.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmailTemplateService} from '@app/_services/email_temp.service';


@Component({
  selector: 'app-add-email-template',
  templateUrl: './add-email-template.component.html',
  styleUrls: ['./add-email-template.component.less'],
})
export class AddEmailTemplateComponent implements OnInit {
	   
	editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '300px',
      minHeight: '300px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    toolbarPosition: 'top',
};
	
	id				:	number;
	cmsRow			:	any;
	currentUser		:	any;
	cmsForm			:	any;
	config			=	{};
	postData		: 	{};
	message 		= '';
    message_class 	= '';
    filter 			= '';
	loading 		= 	false;
    submitted 		= 	false;

	constructor(	
	private router		: Router,
	private formBuilder	: FormBuilder,
	private emailTemplateService	: EmailTemplateService,
	private cmsService	: CmsService,
	private activatedRoute: ActivatedRoute
	) { 

	}

	ngOnInit() {
		
		
			this.currentUser	=	JSON.parse(localStorage.getItem('currentUser'));
			
			this.cmsForm = this.formBuilder.group({
								title				: ['', Validators.required],
								subject				: ['', Validators.required],
								body				: ['', Validators.required],
			})
			
			// this.cmsForm.controls['status'].setValue(0);
		this.activatedRoute.params.subscribe( params => {
				this.id = params.id;
				if(this.id !=undefined){
					this.emailTemplateService.getRow(this.id)
						.then((result:any) => {
						if(result.status === false){
							this.navigateTo('email-templates')
						}
						this.cmsRow	=	result.template_data;
						
						
							this.cmsForm.controls['title'].setValue(this.cmsRow.title);
							this.cmsForm.controls['subject'].setValue(this.cmsRow.subject);
							this.cmsForm.controls['body'].setValue(this.cmsRow.body);

						
						})
						.catch((error) => {
							this.navigateTo('email-templates')
						});
				}
		});
	}
	
	navigateTo(val) {
		  this.router.navigate([val]);
	}
	
		get f() { return this.cmsForm.controls; }
	
	
	submit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.cmsForm.invalid) {
            return;
        }
		
		// this.postData  		= 	{
								// 'added_by'			:this.currentUser.userId,
								// 'title'				:this.f.title.value,
								// 'slug'				:this.f.slug.value,
								// 'meta_title'		:this.f.meta_title.value,
								// 'meta_keyword'		:this.f.meta_keyword.value,
								// 'meta_description'	:this.f.meta_description.value,
								// 'detail'			:this.f.detail.value,
								// 'status'			:this.f.status.value
							// };
		this.postData 	=	this.cmsForm.getRawValue();					
        this.loading = true;
		
			this.emailTemplateService.edit(this.id ,this.postData)
			.then((result:any) => {
					if(result.status === true){
						this.message_class	=	'success';
						this.navigateTo('email-templates')
					}else{
						this.message_class	=	'danger';
					}
					this.message 			= 	result.message
		})
		.catch((error) => {
			this.message_class	=	'danger';	
			this.message 		= 	error;
		});

		
    }


}
