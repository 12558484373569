import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ClientService {
    constructor(private http: HttpClient) { }
	
	
		getAll(data) {
		const  params = new HttpParams({fromObject:data})
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/clients`, {params})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		Add(data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-client`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		deleteUser(id:number) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/delete-user/${id}`,{id:id})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  ); 
		  });
		  return promise;
		}		
		
		getCompany(id:string) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/getCompany/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}
		
		
		editUser(id:number,data:any) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/edit-user/${id}`,data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		getRoles(data:any) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/roles`,data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}
		
		
		getCompanies(filter) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/search-company/${filter}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		getCompanyByName(name) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/check-company/${name}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}

  
}