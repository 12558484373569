import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class CmsService {
    constructor(private http: HttpClient) { }
	
	
		getAll(data) {
		const  params = new HttpParams({fromObject:data})
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/list-pages`, {params})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		Add(data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-page`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		delete(id:number) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/delete-page/${id}`,{id:id})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		
		getRow(id:number) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/page/${id}`,{id:id})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		getRowBySlug(slug:string) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/page-slug/${slug}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}
		
		
		edit(id:number,data:any) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/edit-page/${id}`,data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		getRoles(data:any) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/roles`,data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}

  
}
