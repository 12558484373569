import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService} from '@app/_services/user.service';
import { AuthenticationService } from '@app/_services';
import { ConfirmedValidator } from '../confirmed.validator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
	horizontalPosition	: MatSnackBarHorizontalPosition = 'right';
	verticalPosition	: MatSnackBarVerticalPosition 	= 'top';
	id				: 	number;
	postData		: 	{};
	registerForm	:	FormGroup;
	loading 		= 	false;
    submitted 		= 	false;
    returnUrl		: 	string;
    message 		= 	'';
    message_class 	= 	'';

  constructor(   
		private _snackBar		: MatSnackBar,
		private userService: UserService,
		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService) { 
       
    }

	ngOnInit() {
		
        this.registerForm = this.formBuilder.group({
            first_name			: ['', Validators.required],
            email				: [''],
            notification_email 	: [''],
            password			: [''],
			confirm_password	: ['']
			}, { 
				validator: ConfirmedValidator('password', 'confirm_password')
			})
			
		this.userService.rolesData.subscribe(result => {
			this.id = result.data.id;
			this.registerForm.get('first_name').setValue(result.data.first_name);
			this.registerForm.get('email').setValue(result.data.email);
			this.registerForm.get('notification_email').setValue(result.data.notification_email);
			
		}); 
        
    }
	
	openSnackBar(message) {
		this._snackBar.open(message,'', {
		  duration: 2000,
		  horizontalPosition: this.horizontalPosition,
		  verticalPosition: this.verticalPosition,
		});
	}

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    navigateTo(val) {
		  this.router.navigate([val]);
	}
    submit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
		
		this.userService.updateProfile(this.id,this.registerForm.getRawValue())
			.then((result:any) => {
					this.openSnackBar(result.message);
		})
		.catch((error) => {
			this.openSnackBar('Network error');
		});
		

    }

}
