
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { tap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService} from '@app/_services/user.service';
import { ClientService} from '@app/_services/client.service';
import { EmailTemplateService} from '@app/_services/email_temp.service';


@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.less']
})
export class EmailTemplatesComponent implements OnInit {

	pageEvent		: 	any;
	rolesData		: 	any;
	postData		: 	{};
	userForm		:	FormGroup;
	message 		= '';
    message_class 	= '';
    filter 			= '';
	loading 		= 	false;
    submitted 		= 	false;

    displayedColumns: string[] = ['sr','title', 'subject', 'action'];
    dataSource: MatTableDataSource < any > ;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;


    // MatPaginator Inputs
    length 		= 0;
    pageSize 	= 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];



    constructor(
		private emailTemplateService: EmailTemplateService,
		private clientService		: ClientService,
		private userService			: UserService,
		private router				: Router,
    ) {
		this.userService.rolesData.subscribe(x => {
			this.rolesData	=	x.role_permission;
		}); 

    }

    ngOnInit() {
        this.loadData();
    }
	

	
	navigateTo(val) {
		  this.router.navigate([val]);
	}	

    loadData() {
		this.message = '';
        const data = {
            'filter'	: this.filter,
            'sortOrder'	: this.sort!=undefined?this.sort.direction:'asc',
            'sortField'	: this.sort!=undefined?this.sort.active:'first_name',
            'page'		: this.paginator.pageIndex,
            'limit'		: this.paginator.pageSize!=undefined?this.paginator.pageSize:5
        }
        this.emailTemplateService.getAll(data).then((result: any) => {
                if (result.status === true) {
					this.dataSource 			= new MatTableDataSource(result.template_data);
					this.length 				= result.count;
                } else {
					this.dataSource 			= new MatTableDataSource();
                    this.message 		= result.message;
                }
               
            })
            .catch((error) => {
				this.dataSource 			= new MatTableDataSource();
                this.message_class 	= 'danger';
                this.message 		= 'Network error';
                console.log(error)
            });

    }
	
	checkPermission(jsObjects,key){
		if(jsObjects != undefined){
			let  data  = jsObjects.filter(obj => {
						return obj.permission_key === key && obj.permission_value===1;
					})

			if(data.length){
				return true;
			}else{
				return false;
			}

		}
	}
	
		
	hideAction(val1) {
		 if(this.checkPermission(this.rolesData,val1)){
			 return false;
		 }else{
			 return true;
		 }
		 
	}	
	
	applyFilter() {
		this.filter		=	(document.getElementById("filter") as HTMLInputElement).value;
		// if(this.filter !=''){
			this.paginator.pageIndex = 0;
			this.loadData();
		// }
    }

 

    ngAfterViewInit() {

        this.paginator.page
            .pipe(
                tap(() => this.loadData())
            )
            .subscribe();
			
		this.sort.sortChange
            .pipe(
                tap(() => {
							this.paginator.pageIndex = 0;
							this.loadData()
						  })
            )
            .subscribe();	
			
    }
	
	

}
