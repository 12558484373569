import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { ClaimsService} from '@app/_services/claims.service';
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.less']
})
export class ThankYouComponent implements OnInit {

  constructor(
  		private claimsService	: ClaimsService,
		private authenticationService		: AuthenticationService,
	) { 
  
  		this.authenticationService.getSettings()
			.then((result:any) => {
		})
		.catch((error) => {
		});
		let inviteId	=	localStorage.getItem('inviteId');
		this.claimsService.updateInvite(inviteId).then((result: any) => {
			localStorage.removeItem('currentUser');
			localStorage.removeItem('inviteId');
			localStorage.removeItem('claimId');
			localStorage.removeItem('companyId');
							
			// if (result.status === true) {
				// this.dataSource 			= new MatTableDataSource(result.data);
				// this.length 				= result.count;
			// } else {
				// this.message 		= result.message;
			// }
		   
		})
		.catch((error) => {
			// this.message_class 	= 'danger';
			// this.message 		= 'Network error';
			// console.log(error)
		});
		
		
		
  }

  ngOnInit() {
  }

}
