import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './auth/login.component';
import { RegisterComponent } from './auth/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password.component'
import { ResetPasswordComponent } from './auth/reset-password.component'
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component'

import { CompaniesComponent } from './companies/companies.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { AddRoleComponent } from './role-management/add-role/add-role.component'
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';

import { AuthGuard } from './_helpers';
import { Role } from './_models';
const routes: Routes = [
    {
        path: '',
        component: CompaniesComponent,
		canActivate: [AuthGuard],
		data : {name : 'dashboard'}
    },			 
	{
        path: 'dashboard',
        component: DashboardComponent,
		canActivate: [AuthGuard],
		data : {name : 'dashboard'}
    },				
	{
        path: 'users',
        component: UsersComponent,
		canActivate: [AuthGuard],
		data : {name : 'showUser'}
    },	
	{
        path: 'companies',
        component: CompaniesComponent,
		canActivate: [AuthGuard],
		data : {name : 'showCompanies'}
    },	
	{
        path: 'role-management',
        component: RoleManagementComponent,
		canActivate: [AuthGuard],
		data : {name : 'showRole'}
    },			
	{
        path: 'add-role',
        component: AddRoleComponent,
		canActivate: [AuthGuard],
		data : {name : 'addRole'}
    },	
	{
        path: 'edit-role/:id',
        component: AddRoleComponent,
		canActivate: [AuthGuard],
		data : {name : 'editRole'}
    },
    {
        path: 'login',
        component: LoginComponent,
		// canActivate: [AuthGuard],
		// data : {name : 'dashboard'}
    },   	
	{
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },	
	{
        path: 'reset-password/:token',
        component: ResetPasswordComponent
    },	
	{
        path: 'settings',
        component: SettingsComponent,
		canActivate: [AuthGuard],
		data : {name : 'siteSettings'}
    },	
	{
        path: 'profile',
        component: ProfileComponent,
		canActivate: [AuthGuard],
		data : {name : 'settings'}
    }
];

export const appRoutingModule = RouterModule.forRoot(routes);
