import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { ConfirmedValidator } from '../confirmed.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
	postData		: 	{};
	registerForm	:	FormGroup;
	loading 		= 	false;
    submitted 		= 	false;
    returnUrl		: 	string;
    message 		= 	'';
    message_class 	= 	'';

  constructor(   
		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

	ngOnInit() {
        this.registerForm = this.formBuilder.group({
            first_name			: ['', Validators.required],
            last_name			: [''],
            email				: ['',[Validators.required, Validators.email]],
            phone				: ['', Validators.required],
            password			: ['',[Validators.required,Validators.minLength(6)]],
			confirm_password	: ['', [Validators.required]]
			}, { 
				validator: ConfirmedValidator('password', 'confirm_password')
			})
        

        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    navigateTo(val) {
		  this.router.navigate([val]);
	}
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
		
		this.postData  		= 	{
								'first_name':this.f.first_name.value,
								'last_name':this.f.last_name.value,
								'email':this.f.email.value,
								'phone':this.f.phone.value,
								'password':this.f.password.value
							};
        this.loading = true;
        this.authenticationService.register(this.postData)
            .pipe(first())
            .subscribe(response=>{
					if(response.status === true){
						this.message_class	=	'success';
					}else{
						this.message_class	=	'danger';
					}
					this.message 			= 	response.message
			})
    }

}
