
import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CmsService} from '@app/_services/cms.service';
import { AuthenticationService } from '@app/_services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
})
export class SettingsComponent implements OnInit { 
	horizontalPosition	: MatSnackBarHorizontalPosition = 'right';
	verticalPosition	: MatSnackBarVerticalPosition 	= 'top';
	id				:	number;
	cmsRow			:	any;
	currentUser		:	any;
	cmsForm			:	any;
	numbers			:	any;
	config			=	{};
	postData		: 	{};
	powered_by 		= '';
	logo 			= '';
	favicon 		= '';
	message 		= '';
    message_class 	= '';
    filter 			= '';
	loading 		= 	false;
    submitted 		= 	false;
	fileToUpload	: any = [];

	constructor(	
	private _snackBar		: MatSnackBar,
	private router			: Router,
	private formBuilder		: FormBuilder,
	private authenticationService		: AuthenticationService,
	private activatedRoute	: ActivatedRoute
	) { 

	}

	ngOnInit() {
		
			this.numbers = new Array(101).fill(1).map((x,i)=>i); // [0,1,2,3,4,...,100]
			this.currentUser	=	JSON.parse(localStorage.getItem('currentUser'));
			
			this.cmsForm = this.formBuilder.group({
								title				: ['', Validators.required],
								meta_title			: [''],
								meta_tags			: [''],
								meta_description	: [''],
								epw_cost	: ['1'],
								email_from			: [''],
								password			: [''],
								email_service		: [''],

			})
			
			
			
		this.authenticationService.getSettings()
			.then((result:any) => {
					if(result.status === true){
						this.cmsForm.get('title').setValue(result.data.title);
						this.cmsForm.get('meta_title').setValue(result.data.meta_title);
						this.cmsForm.get('meta_tags').setValue(result.data.meta_tags);
						this.cmsForm.get('meta_description').setValue(result.data.meta_description);
						this.cmsForm.get('epw_cost').setValue(result.data.epw_cost_new);
						this.cmsForm.get('email_from').setValue(result.data.email_from);
						//this.cmsForm.get('password').setValue(result.data.password);
						this.cmsForm.get('email_service').setValue(result.data.email_service);
						
						this.powered_by 	= result.data.powered_by;
						this.logo 			= result.data.logo;
						this.favicon 		= result.data.favicon;
						
						// this.message_class	=	'success';
						// this.navigateTo('cms')
					}else{
						// this.message_class	=	'danger';
					}
					// this.message 			= 	result.message
		})
		.catch((error) => {
			// this.message_class	=	'danger';	
			// this.message 		= 	error;
		});
		
	}
	
	openSnackBar(message) {
		this._snackBar.open(message,'', {
		  duration: 2000,
		  horizontalPosition: this.horizontalPosition,
		  verticalPosition: this.verticalPosition,
		});
	}
	
	handleFileInput(files,type) {
		var reader :any = new FileReader();
		reader.readAsDataURL(files.item(0)); 
		reader.onload = (e) => {
		  this[type]	= e.target.result;
		}
		this.fileToUpload[type] = files.item(0);
	}
	
	navigateTo(val) {
		  this.router.navigate([val]);
	}
	

	get f() { return this.cmsForm.controls; }
	
	
	submit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.cmsForm.invalid) {
            return;
        }
		
		var myFormData = new FormData();
		
		myFormData.append('data', JSON.stringify(this.cmsForm.getRawValue()));
		if(this.fileToUpload.logo !=""){
			myFormData.append('logo', this.fileToUpload.logo);
		}	
		if(this.fileToUpload.favicon !=""){
			myFormData.append('favicon', this.fileToUpload.favicon);
		}		
		if(this.fileToUpload.powered_by !=""){
			myFormData.append('powered_by', this.fileToUpload.powered_by);
		}
		

			this.authenticationService.updateSettings(myFormData)
			.then((result:any) => {
					this.openSnackBar(result.message);
		})
		.catch((error) => {
			this.openSnackBar('Network error');
		});

		
    }


}
