import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { tap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService} from '@app/_services/user.service';
import {ConfirmationDialog} from '../confirm-box/confirmation-dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';


@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.less']
})
export class RoleManagementComponent implements OnInit {
	horizontalPosition		: MatSnackBarHorizontalPosition = 'right';
	verticalPosition		: MatSnackBarVerticalPosition 	= 'top';
	rolesData		: 	any;
	pageEvent		: 	any;
	postData		: 	{};
	userForm		:	FormGroup;
	message 		= 	'';
    message_class 	= 	'';
    filter 			= 	'';
    roleId 			= 	0;
	loading 		= 	false;
    submitted 		= 	false;

    displayedColumns: string[] = ['sr','role_name','action'];
    dataSource: MatTableDataSource < any > ;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;


    // MatPaginator Inputs
    length 		= 0;
    pageSize 	= 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];



    constructor(
		public dialog		: MatDialog,
		private _snackBar		: MatSnackBar,
		private userService		: UserService,
		private router			: Router,
    ) {

		this.userService.rolesData.subscribe(x => {
			this.rolesData	=	x.role_permission;
			this.roleId		=	x.role_id;

		}); 
    }

    ngOnInit() {
        this.loadData();
    }
	
	openSnackBar(message) {
		this._snackBar.open(message,'', {
		  duration: 3000,
		  horizontalPosition: this.horizontalPosition,
		  verticalPosition: this.verticalPosition,
		});
	}
	
	navigateTo(val) {
		  this.router.navigate([val]);
	}	
	navigateNsave(val) {
		localStorage.setItem('companyId',val);
		  this.router.navigate(['all-claims']);
	}

    loadData() {
		this.message = '';
        const data = {
            'filter'	: this.filter,
            'sortOrder'	: this.sort!=undefined?this.sort.direction:'',
            'sortField'	: this.sort!=undefined?this.sort.active:'',
            'page'		: this.paginator.pageIndex,
			'limit'		: this.paginator.pageSize!=undefined?this.paginator.pageSize:''
         }
         console.log(data,198198)

        this.userService.getRoles(data).then((result: any) => {
                if (result.status === true) {
					this.dataSource 			= new MatTableDataSource(result.data);
					this.length 				= result.count;
                } else {
					this.dataSource 			= new MatTableDataSource(); 
                    this.message 		= result.message;
                }
               
            })
            .catch((error) => {
				this.dataSource 			= new MatTableDataSource();
                this.message_class 	= 'danger';
                this.message 		= 'Network error';
                console.log(error)
            });

    }
	
	
	checkPermission(jsObjects,key){
		if(jsObjects != undefined){
			let  data  = jsObjects.filter(obj => {
						return obj.permission_key === key && obj.permission_value===1;
					})
			if(data.length){
				return true;
			}else{
				return false;
			}

		}
	}
	
	hideAction(val1,val2) {
		 if(this.checkPermission(this.rolesData,val1) || this.checkPermission(this.rolesData,val2)){
			 return false;
		 }else{
			 return true;
		 }
		 
	}	
	
	removeRow(data){
		const dialogRef = this.dialog.open(ConfirmationDialog,{
		  data:{
			message: 'Are you sure you want to delete this?',
			buttonText: {
			  ok: 'Yes',
			  cancel: 'No'
			}
		  }
		});

		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
		if (confirmed) {

			this.userService.deleteRole(data.id).then((result: any) => {
					if (result.status === true) {
						this.loadData();
					}else{
					}
				   
				this.openSnackBar(result.message);
				   
			})
			.catch((error) => {
				this.openSnackBar('Network error');
			});
			  }
		});
	} 
	

    applyFilter() {
		this.filter		=	(document.getElementById("filter") as HTMLInputElement).value;
		//if(this.filter !=''){
			this.paginator.pageIndex = 0;
			this.loadData();
		//}
    }

    ngAfterViewInit() {

        this.paginator.page
            .pipe(
                tap(() => this.loadData())
            )
            .subscribe();
			
		this.sort.sortChange
            .pipe(
                tap(() => {
							this.paginator.pageIndex = 0;
							this.loadData()
						  })
            )
            .subscribe();	
			
    }
	
	

}
