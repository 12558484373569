
import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService} from '@app/_services/client.service';
import { UserService} from '@app/_services/user.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.less']
})
export class AddRoleComponent implements OnInit {

	id				:	number;
	cmsRow			:	any;
	currentUser		:	any;
	cmsForm			:	any;
	config			=	{};
	postData		: 	any;
	message 		= '';
    message_class 	= '';
    companiesData 	= '';
    filter 			= '';
    roleId 			= 0;
	loading 		= 	false;
    submitted 		= 	false;

	constructor(	
	private router		: Router,
	private formBuilder	: FormBuilder,
	private clientService	: ClientService,
	private userService	: UserService,
	private activatedRoute: ActivatedRoute
	) { 
		this.userService.rolesData.subscribe(x => {
			this.roleId		=	x.role_id;

		}); 
	}

	ngOnInit() {
		
		
			this.currentUser	=	JSON.parse(localStorage.getItem('currentUser'));
			
			this.cmsForm = this.formBuilder.group({
								role_name			: ['',[ Validators.required]],
								selectAll			: [false],
								showRole			: [false],
								addRole				: [false],
								editRole			: [false],
								deleteRole			: [false],
								showUser			: [false],
								addUser				: [false],
								editUser			: [false],
								deleteUser			: [false],
								showReports			: [false],
								editReport			: [false],
								deleteReport		: [false],
								reviewReport		: [false],
								downloadReport		: [false],
								changeReportStatus	: [false],
								showClients			: [false],
								addClient			: [false],
								searchClient		: [false],
								viewCaseNotes		: [false],
								showClaims			: [false],
								addClaim			: [false],
								editClaim			: [false],
								reviewClaim			: [false],
								downloadClaim		: [false],
								printClaim			: [false],
								exportClaimCSV		: [false],
								showCMS				: [false],
								addCMS				: [false],
								editCMS				: [false],
								deleteCMS			: [false],
								changeProjectStatus	: [false],
								settings			: [false],
								siteSettings		: [false],
								showTemplates		: [false],
								editTemplate		: [false],
			})
			
		this.activatedRoute.params.subscribe( params => {
				this.id = params.id;
				if(this.id !=undefined){
					this.userService.getRoleRow(this.id)
						.then((result:any) => {
						if(result.status === false){
							this.navigateTo('role-management')
						}else{
						
						     
						if (result.role_data.editable == 1 || result.role_data.id == this.roleId ) {
							this.navigateTo('role-management');
						}else{
	
							this.cmsForm.get('role_name').setValue(result.role_data.role_name);
							for(let i in result.role_permissions){
								this.cmsForm.get(result.role_permissions[i].permission_key).setValue(result.role_permissions[i].permission_value);		
							}
							
						}	
						}
						// this.cmsRow	=	result.data;
						
						
			
						})
						.catch((error) => {
							this.message_class	=	'danger';	
							this.message 		= 	error;
							this.navigateTo('role-management')
						});
				}
		});
			
	}
	
	navigateTo(val) {
		  this.router.navigate([val]);
	}		
	
	changeSelect() {
		this.cmsForm.get('selectAll').setValue(false);
	}
	
	selectAll() {
		let val = this.cmsForm.get('selectAll').value;
			if(val == true){
				this.cmsForm.get('showRole').setValue(true);
				this.cmsForm.get('addRole').setValue(true);
				this.cmsForm.get('editRole').setValue(true);
				this.cmsForm.get('deleteRole').setValue(true);
				this.cmsForm.get('showUser').setValue(true);
				this.cmsForm.get('addUser').setValue(true);
				this.cmsForm.get('editUser').setValue(true);
				this.cmsForm.get('deleteUser').setValue(true);		

				this.cmsForm.get('showReports').setValue(true);
				this.cmsForm.get('editReport').setValue(true);
				this.cmsForm.get('deleteReport').setValue(true);
				this.cmsForm.get('reviewReport').setValue(true);
				this.cmsForm.get('downloadReport').setValue(true);
				this.cmsForm.get('changeReportStatus').setValue(true);
				this.cmsForm.get('showClients').setValue(true);
				this.cmsForm.get('addClient').setValue(true);
				this.cmsForm.get('searchClient').setValue(true);
				this.cmsForm.get('viewCaseNotes').setValue(true);
				this.cmsForm.get('showClaims').setValue(true);
				this.cmsForm.get('addClaim').setValue(true);
				this.cmsForm.get('editClaim').setValue(true);
				this.cmsForm.get('reviewClaim').setValue(true);
				this.cmsForm.get('downloadClaim').setValue(true);
				this.cmsForm.get('printClaim').setValue(true);
				this.cmsForm.get('exportClaimCSV').setValue(true);
				this.cmsForm.get('showCMS').setValue(true);
				this.cmsForm.get('addCMS').setValue(true);
				this.cmsForm.get('editCMS').setValue(true);
				this.cmsForm.get('deleteCMS').setValue(true);
				this.cmsForm.get('changeProjectStatus').setValue(true);
				this.cmsForm.get('settings').setValue(true);
				this.cmsForm.get('siteSettings').setValue(true);
				this.cmsForm.get('showTemplates').setValue(true);
				this.cmsForm.get('editTemplate').setValue(true);
			}else{
				this.cmsForm.get('showRole').setValue(false);
				this.cmsForm.get('addRole').setValue(false);
				this.cmsForm.get('editRole').setValue(false);
				this.cmsForm.get('deleteRole').setValue(false);
				this.cmsForm.get('showUser').setValue(false);
				this.cmsForm.get('addUser').setValue(false);
				this.cmsForm.get('editUser').setValue(false);
				this.cmsForm.get('deleteUser').setValue(false);
				
				this.cmsForm.get('showReports').setValue(false);
				this.cmsForm.get('editReport').setValue(false);
				this.cmsForm.get('deleteReport').setValue(false);
				this.cmsForm.get('reviewReport').setValue(false);
				this.cmsForm.get('downloadReport').setValue(false);
				this.cmsForm.get('changeReportStatus').setValue(false);
				this.cmsForm.get('showClients').setValue(false);
				this.cmsForm.get('addClient').setValue(false);
				this.cmsForm.get('searchClient').setValue(false);
				this.cmsForm.get('viewCaseNotes').setValue(false);
				this.cmsForm.get('showClaims').setValue(false);
				this.cmsForm.get('addClaim').setValue(false);
				this.cmsForm.get('editClaim').setValue(false);
				this.cmsForm.get('reviewClaim').setValue(false);
				this.cmsForm.get('downloadClaim').setValue(false);
				this.cmsForm.get('printClaim').setValue(false);
				this.cmsForm.get('exportClaimCSV').setValue(false);
				this.cmsForm.get('showCMS').setValue(false);
				this.cmsForm.get('addCMS').setValue(false);
				this.cmsForm.get('editCMS').setValue(false);
				this.cmsForm.get('deleteCMS').setValue(false);
				this.cmsForm.get('changeProjectStatus').setValue(false);
				this.cmsForm.get('settings').setValue(false);
				this.cmsForm.get('siteSettings').setValue(false);
				this.cmsForm.get('showTemplates').setValue(false);
				this.cmsForm.get('editTemplate').setValue(false);
			}
		  // console.log(this.cmsForm.get('selectAll').value)
	}	
	

	get f() { return this.cmsForm.controls; }
	
	
	submit() {
		// console.log(this.cmsForm)
        this.submitted = true;
        // stop here if form is invalid
        if (this.cmsForm.invalid) {
            return;
        }
		let data 						=  this.cmsForm.getRawValue()
		this.postData  					= {};
		this.postData.role_name 		= data.role_name;
		this.postData.role_permissions  = data;
		
		// delete this.postData.role_permissions.selectAll;
		delete this.postData.role_permissions.role_name;

		
		if(this.id !=undefined){
			this.userService.editRole(this.id,this.postData)
				.then((result:any) => {
						if(result.status === true){
							this.message_class	=	'success';
							this.navigateTo('role-management')
						}else{
							this.message_class	=	'danger';
						}
						this.message 			= 	result.message
			})
			.catch((error) => {
				this.message_class	=	'danger';	
				this.message 		= 	error;
			});
		}else{
			this.userService.addRoles(this.postData)
				.then((result:any) => {
						if(result.status === true){
							this.message_class	=	'success';
							this.navigateTo('role-management')
						}else{
							this.message_class	=	'danger';
						}
						this.message 			= 	result.message
			})
			.catch((error) => {
				this.message_class	=	'danger';	
				this.message 		= 	error;
			});
		}
		
		
    }


}
