import { Component,Inject } from '@angular/core';
import { Router,ActivatedRoute ,   Event as RouterEvent,  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { AuthenticationService } from './_services';
import { User, Role } from './_models';
import { UserService} from '@app/_services/user.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    showNavMenu	=	 false;
    showMenu	=	 false;
    loading		=	 true;
    currentUser	:	 any;
	rolesData	:	any;
    isHome		= 	false ;
	logo		= '';
	powered_by	= '';
	site_title	= '';

    constructor(
		private userService: UserService,
		    private meta: Meta,
        private router: Router,
		private activatedRoute:ActivatedRoute,
        private authenticationService: AuthenticationService,
		@Inject(DOCUMENT) private _document: HTMLDocument
    ) {
		   this.router.events.subscribe((e : RouterEvent)=> {
			 this.navigationInterceptor(e);
		   if( this.router.url == '/dashboard' || this.router.url == '/login'|| this.router.url == '/thank-you'){
				this.isHome = true;
			}else{
				this.isHome = false;
			}
		   });
		   
		this.userService.rolesData.subscribe(x => {
			this.rolesData	=	x.role_permission;
			
		}); 
		
		this.authenticationService.siteSetting.subscribe(x => {
			this.logo 		=	x.logo;
			this.site_title	=	x.title; 
			this._document.getElementById('appFavicon').setAttribute('href',x.favicon);
			if(x.title!=undefined){
				this._document.getElementById('mainTitle').innerHTML=x.title;
				
			this.meta.updateTag({ name: 'keywords', content: x.meta_tags });
			this.meta.updateTag({ name: 'description', content: x.meta_description });
			}
			this.powered_by 	=	x.powered_by;
		}); 
		
        this.authenticationService.currentUser.subscribe(x => {
			this.isHome = true;
			this.currentUser = x;
		});        
		
		
		
		
	}
	
		showHideMenu() {
			this.showMenu	= !this.showMenu;
		}		
		showHideNavMenu() {
			this.showNavMenu	= !this.showNavMenu;
		}	

		onActivate(event) {
			window.scroll(0,0);
			
		}
	
	
		navigationInterceptor(event: RouterEvent): void {
			this.showNavMenu	=	 false;
			this.showMenu	=	 false;

			if (event instanceof NavigationStart) {
			  this.loading = true
			}
			if (event instanceof NavigationEnd) {
			  this.loading = false
			}

			// Set loading state to false in both of the below events to hide the spinner in case a request fails
			if (event instanceof NavigationCancel) {
			  this.loading = false
			}
			if (event instanceof NavigationError) {
			  this.loading = false
			}
		}

    get isAdmin() {
        return this.currentUser && this.currentUser.role === Role.Admin;
    }
	
	checkPermission(jsObjects,key){
		if(jsObjects != undefined){
			let  data  = jsObjects.filter(obj => {
						return obj.permission_key === key && obj.permission_value===1;
					})
			if(data.length){
				return true;
			}else{
				return false;
			}

		}
	}


	log(val) { console.log(val); }


    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
