import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) { }
	getAll(data) {
		const  params = new HttpParams({fromObject:data})
	  	let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/companies`, {params})
	  		.toPromise()
	  		.then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
	  		);
  		});
	  	return promise;
	}

	ExportCompanies(data) {
		const  params = new HttpParams({fromObject:data})
		let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/companies/export`, {params})
		  	.toPromise()
		  	.then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
		  	);
	  	});
	  	return promise;
	}

	uploadCsv(data:any) {
		let promise = new Promise((resolve, reject) => {
		  this.http.post<any>(`${environment.apiUrl}api/upload-csv`, data)
			.toPromise()
			.then(
			  res => { // Success
				  resolve(res);
				  // this.currentUserSubject.next(res);
			  },
			  msg => { // Error
				  reject(msg);
			  }
			);
		});
		return promise;
	  }	


	uploadCsvTest(data:any) {
		let promise = new Promise((resolve, reject) => {
		  this.http.post<any>(`${environment.apiUrl}api/upload-csv-test`, data)
			.toPromise()
			.then(
			  res => { // Success
				  resolve(res);
				  // this.currentUserSubject.next(res);
			  },
			  msg => { // Error
				  reject(msg);
			  }
			);
		});
		return promise;
	  }	



}
