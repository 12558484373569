import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService} from '@app/_services/user.service';
import { ClientService} from '@app/_services/client.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
	horizontalPosition		: MatSnackBarHorizontalPosition = 'right';
	verticalPosition		: MatSnackBarVerticalPosition 	= 'top';
	
	dashForm		:	FormGroup;
	showSearch		= 	false;
	clientData		: 	any;
	companiesData	: 	any;
	rolesData		:	any;
	selectedId		=	'';
	selectedValue	=	'';
	logo			=	'';
	name			=	'';
	site_title		= 	'';


	constructor(
		private _snackBar		: MatSnackBar,
        private clientService: ClientService,
        private userService: UserService,
        private router: Router,
		private authenticationService: AuthenticationService
    ) {
		
		this.userService.rolesData.subscribe(x => {
			var last_name	=	(!x.data.last_name) ? '' : x.data.last_name ;
			this.name		=	x.data.first_name+' '+last_name;
			this.rolesData	=	x.role_permission;
		}); 
		
		this.authenticationService.siteSetting.subscribe(x => {
			this.logo 		=	x.logo;
			this.site_title =   x.title;
			
		}); 
		
		let data ={'dd':'ee'}; 
		this.clientService.getAll(data).then((result: any) => {
			if (result.status === true) {
				this.clientData	=	result.data;
			} 
		   
		})
		.catch((error) => {
		});
		
	}
	
	openSnackBar(message) {
		this._snackBar.open(message,'', {
		  duration: 3000,
		  horizontalPosition: this.horizontalPosition,
		  verticalPosition: this.verticalPosition,
		});
	}
	
	searchClient(){
		this.selectedValue 	= '';
		this.selectedId 	= '';
		this.companiesData 	= '';
		this.showSearch	= !this.showSearch;
	}
	searchCompany(event){
			this.selectedValue 	= "";
			this.selectedId 	= "";
            var searchField 	= event.target.value;
			
            var regex = new RegExp(searchField, "i");
			var array 	=	[];
				
			  this.clientData.map(function(val, index){
				if ((val.company_name.search(regex) != -1)) {
					array.push(val);
				}
			  });
			this.companiesData = array;
       
	}
	submitSearch(){
		if(this.selectedId == ''){
			this.openSnackBar('Please type client name correctly.');
		}else{
			this.showSearch	= !this.showSearch;
			localStorage.setItem('companyId',this.selectedId);
			 this.router.navigate(['all-claims']);
		}
		
	}
	
	getCompanyData(val){
		this.selectedValue = val.company_name;
		this.selectedId 	= val.company_id;
		this.companiesData = '';
	}
	
	checkPermission(jsObjects,key){
		if(jsObjects != undefined){
			let  data  = jsObjects.filter(obj => {
						return obj.permission_key === key && obj.permission_value===1;
					})
			if(data.length){
				return true;
			}else{
				return false;
			}

		}
	}
	
	  ngOnInit() {
    }
	
	
	
    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

}
