import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ClaimsService {
	private 	inviteUser				= new BehaviorSubject({name: 'Maradona'});
	public 	claimModule				= new BehaviorSubject({name: 'Maradona'});
	public 		InviteUserPermissions	: Observable<any>;
	public 		ClaimCompleted			: Observable<any>;
	constructor(private http: HttpClient) {
		this.InviteUserPermissions 	= this.inviteUser.asObservable();
		this.ClaimCompleted 		= this.claimModule.asObservable();

	}
	
	
		getAll(company_id,data) {
			
			// let params = new HttpParams();
// params = params.append('myArray', JSON.stringify(myArray))
			
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/getAllClaims/${company_id}`,{params:data})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		
		AddBasic(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/company-basics/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		GetAbout(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/get-about/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		AddAbout(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/about-company/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		AddInvite(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/invite/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		AddProject(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-project/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		
		updateInvite(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/update-invite-token/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		updateClaim(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.put<any>(`${environment.apiUrl}api/update-claim/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		updateClaimProgress(id,module) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/update-claim-progress/${id}/${module}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}			
		
		EditProject(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.put<any>(`${environment.apiUrl}api/edit-project/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		
		
		DownloadCsv(id,data) {
		const  params = new HttpParams({fromObject:data})
		  let promise = new Promise((resolve, reject) => {
		  this.http.get<any>(`${environment.apiUrl}api/claims-download-csv/${id}`, {params})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		
		
		DeleteProject(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.delete<any>(`${environment.apiUrl}api/delete-project/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		DeleteInvite(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.delete<any>(`${environment.apiUrl}api/delete-invite/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		ListStaffCost(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/view-staff-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		getStaffCostsRow(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/view-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		AddStaffCost(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-staff-costs/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		EditStaffCost(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/edit-staff-costs/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		DeleteStaffCost(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.delete<any>(`${environment.apiUrl}api/delete-staff-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		ListEwpCost(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/list-epw-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		getEwpCostsRow(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/view-epw-cost/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		AddEwpCost(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-epw-costs/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		EditEwpCost(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.put<any>(`${environment.apiUrl}api/edit-epw-costs/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		DeleteEwpCost(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.delete<any>(`${environment.apiUrl}api/delete-epw-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}


		ListSubCost(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/list-sub-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		getSubCostsRow(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/view-sub-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		AddSubCost(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-sub-costs/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		EditSubCost(id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.put<any>(`${environment.apiUrl}api/edit-sub-costs/${id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		DeleteSubCost(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.delete<any>(`${environment.apiUrl}api/delete-sub-costs/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}
		

		AddCosts(claim_id,type,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-costs/${claim_id}/${type}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		
		ListCosts(claim_id,type) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/list-costs/${claim_id}/${type}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}			

		AddCommercialGoals(claim_id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-commercial-goals/${claim_id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		
		ListCommercialGoals(claim_id,type) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/list-commercial-goals/${claim_id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		ListTechnicalClaims(claim_id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/list-technical-claims/${claim_id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		AddAims(claim_id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-aims/${claim_id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		AddDifficulties(claim_id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-difficulties/${claim_id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		
		AddTechnicalChallenge(claim_id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-technical-challenge/${claim_id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		

		AddNonTechnicalArea(claim_id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-non-technical-area/${claim_id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}			
		AddDeclaration(claim_id,data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-declaration/${claim_id}`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		
		Add(data) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/add-claim`, data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		delete(id:number) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/delete-page/${id}`,{id:id})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}		
		
		getRow(id:string) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/getClaims/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					this.claimModule.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		getRowBySlug(slug:string) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/page-slug/${slug}`,{slug:slug})
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}
		
		
		edit(id:number,data:any) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/edit-page/${id}`,data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		getRoles(data:any) {
		  let promise = new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}api/roles`,data)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					// this.currentUserSubject.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	

		getInvitedUser(token) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/invite-token/${token}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					this.inviteUser.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}	
		getInvitedData(id) {
		  let promise = new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}api/invite-data/${id}`)
			  .toPromise()
			  .then(
				res => { // Success
					resolve(res);
					this.inviteUser.next(res);
				},
				msg => { // Error
					reject(msg);
				}
			  );
		  });
		  return promise;
		}
		
		

		
		


  
}