import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpErrorResponse } from '@angular/common/http';
import { Observable,throwError  } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	horizontalPosition		: MatSnackBarHorizontalPosition = 'right';
	verticalPosition		: MatSnackBarVerticalPosition 	= 'top';
    constructor(private _snackBar		: MatSnackBar,private authenticationService: AuthenticationService,private router		: Router) { }
	
	
	openSnackBar(message) {
		this._snackBar.open(message,'', {
		  duration: 3000,
		  horizontalPosition: this.horizontalPosition,
		  verticalPosition: this.verticalPosition,
		});
	}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const isLoggedIn = currentUser && currentUser.apiToken;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.apiToken}`
                }
            });
        }

        return next.handle(request)
		
		.pipe(
       catchError((error: HttpErrorResponse) => {
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // client-side error
           errorMessage = `Error: ${error.error.message}`;
         } else {
           if(error.status==401){
				localStorage.removeItem('claimId');
				localStorage.removeItem('companyId');
				localStorage.removeItem('currentUser');
				this.openSnackBar('Your session has been expired!');
				this.router.navigate(['/login']);
		   }
         }
         return throwError(errorMessage);
       })
     )
    }
}